const initialState = {
  country: "CA",
  province: {
    id: "",
    name: "",
  },
  city: {
    id: "",
    name: "",
    region_name: "",
  },
  date: "",
  recipient: {
    firstName: "",
    lastName: "",
    address: "",
    addressType: "",
    city: "",
    postalCode: "",
    deliveryNote: "",
    other: "",
    refFirstName: "",
    refLastName: "",
    refEmail: "",
    refPhone: "",
    isShippingAddressSame:"",
    shippingAddress:"",
    shippingCity:"",
    shippingPostalCode:"",
    shippingProvince:"",
  },
  stylesLocked: false,
  deliveryWindow: {
    title: "",
    price: "",
  },
  contactDetails: {
    name: "",
    phone: "",
  },
  primaryBouquets: [],
  secondaryBouquets: [],
  decor: [],
  wearables: [],
  pressedBouquets: [],
  extras: [],
  focalFlowers: [],
  featureFlowers: [],
  standardFlowers: [],
  greenery: [],
  premiumDriedBotanicals: [],
  price: 0,
  resubmission: false,
  recordId: ""
};

export const state = () => ({
  country: "",
  province: {
    id: "",
    name: "",
  },
  city: {
    id: "",
    name: "",
    region_name: ""
  },
  date: "",
  recipient: {
    firstName: "",
    lastName: "",
    address: "",
    addressType: "",
    city: "",
    postalCode: "",
    deliveryNote: "",
    apartment: "",
    other: "",
    refFirstName: "",
    refLastName: "",
    refEmail: "",
    refPhone: "",
    isShippingAddressSame:"",
    shippingAddress:"",
    shippingCity:"",
    shippingPostalCode:"",
    shippingProvince:"",
  },
  stylesLocked: false,
  deliveryWindow: {
    title: "",
    price: "",
  },
  contactDetails: {
    name: "",
    phone: "",
  },
  primaryBouquets: [],
  secondaryBouquets: [],
  decor: [],
  wearables: [],
  pressedBouquets: [],
  extras: [],
  focalFlowers: [],
  featureFlowers: [],
  standardFlowers: [],
  greenery: [],
  premiumDriedBotanicals: [],
  price: 0,
  weddingProductPrice:0,
  bulkProductsPrice:0,
  resubmission: false,
  recordId: ""
});

export const actions = {
  RESUBMISSION_SET({state, commit}, data) {
    commit("setResubmissionData", data);
  },
  COUNTRY_ADD({state, commit}, country) {
    commit("setCountry", country);
  },
  COUNTRY_REMOVE({state, commit}, country) {
    commit("removeCountry", country);
  },
  PROVINCE_ADD({state, commit}, province) {
    commit("setProvince", province);
  },
  PROVINCE_REMOVE({state, commit}, province) {
    commit("removeProvince", province);
  },
  CITY_ADD({state, commit}, city) {
    commit("setCity", city);
  },
  CITY_REMOVE({state, commit}, city) {
    commit("removeCity", city);
  },
  DATE_ADD({state, commit}, date) {
    commit("setDate", date);
  },
  DATE_REMOVE({state, commit}, date) {
    commit("removeDate", date);
  },
  RECIPIENT_ADD({state, commit}, recipient) {
    commit("setRecipient", recipient);
  },
  RECIPIENT_REMOVE({state, commit}, recipient) {
    commit("removeRecipient", recipient);
  },
  UPDATE_STYLES_LOCKED({state, commit}, locked) {
    commit("updateStylesLocked", locked);
  },
  DELIVERY_WINDOW_ADD({state, commit}, deliveryWindow) {
    commit("setDeliveryWindow", deliveryWindow);
  },
  CONTACT_DETAIL_ADD({state, commit}, contactDetails) {
    commit("setContactDetails", contactDetails);
  },
  PRIMARY_BOUQUETS_SET({state, commit}, bouquets) {
    commit("setPrimaryBouquets", bouquets);
  },
  SECONDARY_BOUQUETS_SET({state,commit}, bouquets) {
    commit("setSecondaryBouquets", bouquets)
  },
  DECOR_SET({state, commit}, decor) {
    commit("setDecor", decor);
  },
  WEARABLES_SET({state, commit}, wearables) {
    commit("setWearables", wearables);
  },
  PRICE_SET({state, commit}, price) {
    commit("setPrice", price);
  },
  WEDDING_PRODUCT_PRICE_SET({state, commit}, weddingProductPrice) {
    commit("setWeddingProductPrice", weddingProductPrice);
  },
  BULK_PRODUCT_PRICE_SET({state, commit}, bulkProductPrice) {
    commit("setBulkProductPrice", bulkProductPrice);
  },
  WEDDING_ITEM_FLUSH({state, commit}) {
    commit("flushWeddingItem");
  },
  PRESSED_BOUQUETS_SET({state, commit}, bouquets) {
    commit("setPressedBouquets", bouquets)
  },
  EXTRAS_SET({state, commit}, extras) {
    commit("setExtras", extras);
  },
  FOCAL_FLOWERS_SET({state, commit}, focalFlowers) {
    commit("setFocalFlowers", focalFlowers);
  },
  FEATURE_FLOWERS_SET({state, commit}, featureFlowers) {
    commit("setFeatureFlowers", featureFlowers);
  },
  STANDARD_FLOWERS_SET({state, commit}, standardFlowers) {
    commit("setStandardFlowers", standardFlowers);
  },
  GREENERY_SET({state, commit}, greenery) {
    commit("setGreenery", greenery);
  },
  PREMIUM_DRIED_BOTANICALS_SET({state, commit}, premiumDriedBotanicals) {
    commit("setPremiumDriedBotanicals", premiumDriedBotanicals);
  },
};

export const mutations = {
  setResubmissionData(state, data) {
    state.recordId = data.recordId;
    state.resubmission = data.resubmission;
    state.recipient.firstName = data.recipient.firstName;
    state.recipient.lastName = data.recipient.lastName;
    state.recipient.email = data.recipient.email;
    state.recipient.phone = data.recipient.phone;
  },
  setCountry(state, country) {
    state.country = country;
  },
  removeCountry(state, country) {
    state.country = "";
  },
  setProvince(state, province) {
    state.province = province;
  },
  removeProvince(state, province) {
    state.province = {};
  },
  setCity(state, city) {
    state.city = city;
  },
  removeCity(state, city) {
    state.city = {};
  },
  setDate(state, date) {
    state.date = date;
  },
  removeDate(state, date) {
    state.date = "";
  },
  setRecipient(state, recipient) {
    state.recipient = recipient;
  },
  removeRecipient(state, recipient) {
    state.recipient = {};
  },
  updateStylesLocked(state, locked) {
    state.stylesLocked = locked;
  },
  setDeliveryWindow(state, deliveryWindow) {
    state.deliveryWindow = deliveryWindow;
  },
  setContactDetails(state, contactDetails) {
    state.contactDetails = contactDetails;
  },
  setPrimaryBouquets(state, bouquets) {
    state.primaryBouquets = bouquets;
  },
  setSecondaryBouquets(state, bouquets) {
    state.secondaryBouquets = bouquets;
  },
  setDecor(state, decor) {
    state.decor = decor;
  },
  setWearables(state, wearables) {
    state.wearables = wearables;
  },
  setPrice(state, price) {
    state.price = price;
  },
  setWeddingProductPrice(state,weddingProductPrice){
    state.weddingProductPrice=weddingProductPrice
  },
  setBulkProductPrice(state,bulkProductPrice){
    state.bulkProductPrice=bulkProductPrice
  },
  setPressedBouquets(state, bouquets) {
    state.pressedBouquets = bouquets;
  },
  setExtras(state, extras) {
    state.extras = extras;
  },
  setFocalFlowers(state, focalFlowers) {
    state.focalFlowers = focalFlowers;
  },
  setFeatureFlowers(state, featureFlowers) {
    state.featureFlowers = featureFlowers;
  },
  setStandardFlowers(state, standardFlowers) {
    state.standardFlowers = standardFlowers;
  },
  setGreenery(state, greenery) {
    state.greenery = greenery
  },
  setPremiumDriedBotanicals(state, premiumDriedBotanicals) {
    state.premiumDriedBotanicals = premiumDriedBotanicals
  },
  flushWeddingItem(state) {
    Object.keys(initialState).map((key) => {
      state[key] = initialState[key];
    });
  },
};
