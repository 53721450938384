const axios = require("axios");

async function performSearch(searchQuery) {
    try {
        const searchResult = await axios
            .post(
                "https://shop.callia.com/search/city",
                {
                    "q": searchQuery,
                },
            )
        return searchResult.data.hits;
    } catch (error) {
        console.error("Error performing search:", error);
        throw error;
    }
}

export { performSearch };
