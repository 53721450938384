function clearSessionsData() {
  //remove sessions when change the locale
  sessionStorage.removeItem("country");
  sessionStorage.removeItem("region");
  sessionStorage.removeItem("cityModel");
  sessionStorage.removeItem("deliveryDate");
  // sessionStorage.removeItem("cartItems");
  // this.$store.dispatch("lineItems/CLEAR_CART_ITEMS");
  // this.$store.dispatch("cartItem/CART_ITEM_FLUSH");
  // this.$store.dispatch("weddingItem/WEDDING_ITEM_FLUSH");
  // this.$nuxt.$emit("cartItemsUpdated");
}

export default function ({ app }) {
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale, isInitialSetup, context) => {
    console.log("onBeforeLanguageSwitch called right before setting a new locale");
    console.log(oldLocale, newLocale, isInitialSetup);
  };

  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    console.log("onLanguageSwitched called right after a new locale has been set");
    console.log(oldLocale, newLocale);

    if (
      ((oldLocale === 'en-ca' && newLocale === 'en-us') ||
        (oldLocale === 'en-us' && newLocale === 'en-ca') ||
        (oldLocale === 'fr-ca' && newLocale === 'en-us') ||
        (oldLocale === 'en-us' && newLocale === 'fr-ca'))
      && !sessionStorage.sessionSet
    ) {
      clearSessionsData();
    }

    sessionStorage.removeItem("sessionSet"); // sessionSet - session data set by delivery popup
  };

  const localePath = app.localePath("index");

  const switchLocalePath = app.switchLocalePath("en-ca");
}
