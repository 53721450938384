const initialState = {
  hideAnnouncementBar: false,
};

export const state = () => ({
  hideAnnouncementBar: false,
});

/**
 * Global Actions
 */
export const actions = {
  HIDE_ANNOUNCEMENT_BAR({ state, commit }) {
    commit("hideAnnouncementBar");
  },
  SHOW_ANNOUNCEMENT_BAR({ state, commit }) {
    commit("showAnnouncementBar");
  }
};

export const mutations = {
  showAnnouncementBar(state) {
    state.hideAnnouncementBar = false;
  },
  hideAnnouncementBar(state) {
    state.hideAnnouncementBar = true;
  }
};
