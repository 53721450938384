export default {
  methods: {
    async subscribe(axios: any, name: string, email: string, locale: any, phone: string = "") {
      try {
        await axios.$post("/klaviyo/subscribe", {
          firstName: name,
          email: email,
          lang: locale[0],
          country: locale[1].toUpperCase(),
          phone: phone
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
