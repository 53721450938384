export default function ({ app }) {
  app.router.beforeEach((to, from, next) => {
    const path = to.fullPath;
    if (path.length > 1 && path.endsWith('/')) {
      const newPath = path.slice(0, -1);
      next(newPath);
    } else {
      next();
    }
  });

  app.router.options.scrollBehavior = function(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash === "") return;

    let targetEl = document.querySelector(to.hash);
    if (!targetEl) {
      let observer = new MutationObserver((mutations, observer) => {
        for (let mutation of mutations) {
          if (mutation.type === 'childList') {
            let el = document.querySelector(to.hash);
            if (el) {
              el.scrollIntoView();
              let rect = el.getBoundingClientRect();
              if (rect.top <= 10) { 
                observer.disconnect();
              }
              break;
            }
          }
        }
      });
      observer.observe(document, { childList: true, subtree: true });
    } else {
      targetEl.scrollIntoView();
    }
  };
}
