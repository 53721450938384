import {localeList} from "~/utils/locale";

const initialState = {
  orderInfo: undefined,
  tempUpdatedMessage: undefined,
  regionTag: undefined,
  deliveryDate: undefined,
  selected_card: undefined
};

export const state = () => ({
  orderInfo: {},
  tempUpdatedMessage: "",
  regionTag: "",
  deliveryDate: "",
  selected_card: {},
});

export const actions = {
  ORDER_INFO_SET({state, commit}, event) {
    commit("setOrderInfo", event);
  },

  TEMP_UPDATE_MESSAGE({state, commit}, event) {
    commit("setTempUpdatedMessage", event);
  },

  SET_REGION_TAG({state, commit}, event) {
    commit("setRegionTag", event);
  },

  SET_DELIVERY_DATE({state, commit}, event) {
    commit("setDeliveryDate", event);
  },

  SET_SELECTED_CARD({state, commit}, event) {
    commit("setSelectedCard", event);
  }
};

export const mutations = {
  setOrderInfo(state, order) {
    state.orderInfo = order;
  },
  setTempUpdatedMessage(state, message) {
    state.tempUpdatedMessage = message;
  },
  setRegionTag(state, tag) {
    state.regionTag = tag;
  },
  setDeliveryDate(state, date) {
    state.deliveryDate = date;
  },
  setSelectedCard(state, card) {
    state.selected_card = card;
  }
};
