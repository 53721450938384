import { Amplify } from "aws-amplify";
export default function () {
  const envConfig = {
    identityPoolId: process.env.IDENTITY_POOL_ID,
    region: process.env.REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: process.env.MANDATORY_SIGN_IN,
    authenticationFlowType: process.env.AUTHENTICATION_FLOW_TYPE,
  };
  Amplify.configure(envConfig);
}
