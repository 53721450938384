import Cookies from "js-cookie";

const getCookieValueByName = (key) => {

  return Cookies.get(key);

};

const setCookieValue = (key, value, expires = {}) => {
  try {
    Cookies.set(key, value, expires);
  } catch (error) {
    throw new Error(`Error setting cookie !!! ${error}`);
  }
};
const removeCookieValue = (key) => {
  try {
    Cookies.remove(key);
  } catch (error) {
    throw new Error(`Error removing cookie !!! ${error}`);
  }
};

export { getCookieValueByName, setCookieValue, removeCookieValue };
