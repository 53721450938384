export default {
  methods: {
    validateEventType(eventType) {
      const validEventTypes = ["openWeddingDialog","openEventDialog"];
      if (validEventTypes.includes(eventType)) {
        return true;
      } else {
        console.error("Invalid event type: ", eventType);
        return false;
      }
    },
    emitCustomEvent(event) {
      event.type = event.type.slice(1);
      if (!this.validateEventType(event.type)) return;
      event.valid = true;
      this.$emit("customEvent", event);
    },
    bubbleUpEvent(event) {
      if (!event.valid) return;
      this.$emit("customEvent", event);
    },
    emitEvent(event) {
      if (!event.valid) return;
      this.$emit(event.type, event.data);
    },
    emitCustomButtonEvent(event) {
      event.type = event.type.slice(1);
      if (!this.validateEventType(event.type)) return;
      event.valid = true;
      this.$emit(event.type, event.data);
    },
  },
};
