export const state = () => ({
  cartItems: {},
  discountCode: ""
});

/**
 * Cart Actions
 */
export const actions = {
  FILL_CART_ITEMS({ state, commit }, cartItems) {
    commit("fillCartItems", cartItems);
  },
  ADD_CART_ITEM({ state, commit }, cartItem) {
    commit("addCartItem", cartItem);
  },
  DELETE_CART_ITEM({ state, commit }, cartItemId) {
    commit("deleteCartItem", cartItemId);
  },
  CLEAR_CART_ITEMS({ state, commit }) {
    commit("clearCartItems");
  },
  UPDATE_CART_ITEM({ state, commit }, cartItem) {
    commit("updateCartItem", cartItem);
  },
  SET_DISCOUNT_CODE({ state, commit }, discountCode){
    commit("setDiscountCode", discountCode);
  },
  CLEAR_DISCOUNT_CODE({ state, commit }){
    commit("clearDiscountCode");
  },
};

export const mutations = {
  fillCartItems(state, cartItems) {
    state.cartItems = cartItems;
  },
  addCartItem(state, cartItem) {
    state.cartItems[cartItem.id] = cartItem;
  },
  deleteCartItem(state, cartItemId) {
    delete state.cartItems[cartItemId];
  },
  updateCartItem(state, cartItem) {
    state.cartItems[cartItem.id] = cartItem;
  },
  clearCartItems(state) {
    state.cartItems = {};
  },
  setDiscountCode(state, code){
    state.discountCode = code;
  },
  clearDiscountCode(state){
    state.discountCode = "";
  }
};
