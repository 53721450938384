import { Auth } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { getCookieValueByName } from "~/utils/cookie";

export const state = () => ({
  user: {},
  userAddress: {
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    apartment: "",
    city: "",
    province: "",
    addressTypeModel: "",
    postal: "",
    alias: "",
    delivery: "",
    default: "",
  },
  addresses: [],
  isAuthenticatedUser: false,
});

/**
 * Account Actions
 */
export const actions = {
  async SIGN_IN({ _, commit }, userSignInCread) {
    try {
      let user = await Auth.signIn(
        userSignInCread.email,
        userSignInCread.password
      );
      let userAttributes = {
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        email: user.attributes.email,
        phone: user.attributes.phone_number || "123-456-7890",
        isEmailVerified: user.attributes.email_verified,
      };
      commit("setUser", userAttributes);
      return user;
    } catch (error) {
      throw error;
    }
  },
  async SIGN_UP({ }, userSignUpCread) {
    try {
      const user = await Auth.signUp({
        username: uuid(),
        password: userSignUpCread.password,
        attributes: {
          email: userSignUpCread.email,
          given_name: userSignUpCread.firstName,
          family_name: userSignUpCread.lastName,
        },
      });
      return user;
    } catch (error) {
      throw new Error(`Error while signing up user !!! ${error}`);
    }
  },
  async SIGN_OUT({ state, commit }){
    await Auth.signOut();
    commit("setUser", null);
    commit("setIsAuthenticatedUser", false);
    return state.isAuthenticatedUser;
  },
  async FORGOT_PASSWORD({ _, commit }, recoveryEmail) {
    try {
      const res = await Auth.forgotPassword(recoveryEmail);
      return res
    } catch (error) {
      throw error;
    }
  },
  async AUTHENTICATE_USER({ state, commit }) {
    try {
      let CAL_ACCESS_TOKEN = getCookieValueByName("CAL_ACCESS_TOKEN");
      if (!CAL_ACCESS_TOKEN) {
        commit("setIsAuthenticatedUser", false);
        return false;
      }
      let currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      let currentUserAccessToken =
        currentAuthenticatedUser.signInUserSession.idToken.jwtToken;
      if (
        CAL_ACCESS_TOKEN === currentUserAccessToken &&
        currentAuthenticatedUser.attributes.email_verified
      ) {
        let userAttributes = {
          firstName: currentAuthenticatedUser.attributes.given_name,
          lastName: currentAuthenticatedUser.attributes.family_name,
          email: currentAuthenticatedUser.attributes.email,
          phone:
            currentAuthenticatedUser.attributes.phone_number || "123-456-7890",
          isEmailVerified: currentAuthenticatedUser.attributes.email_verified,
        };
        if (
          userAttributes.phone &&
          userAttributes.phone.length > 8 &&
          userAttributes.phone.startsWith("+1")
        ) {
          userAttributes.phone = userAttributes.phone.substring(2);
        }
        commit("setIsAuthenticatedUser", true);
        commit("setUser", userAttributes);
      }
      return state.isAuthenticatedUser;
    } catch (error) {
      console.error(error);
      commit("setIsAuthenticatedUser", false);
      return false;
    }
  },
  async ADDRESSES({ _, commit }) {
    let addresses = [];
    const CAL_ACCESS_TOKEN = getCookieValueByName("CAL_ACCESS_TOKEN");
    addresses = await this.$axios
      .get("user/address", { headers: { Authorization: CAL_ACCESS_TOKEN } })
      .then((res) => {
        if (res.data) {
          res.data.forEach((element) => {
            let address = {
              firstName: element.firstName,
              lastName: element.lastName,
              address: element.address,
              apartment: element.apartment,
              city: element.city,
              province: element.province,
              postal: element.postal,
              addressTypeModel: element.addressTypeModel,
              phone: element.phone,
              delivery: element.delivery,
              alias: element.alias,
              default: element.default,
              created_at: element.created_at,
            };
            addresses.push(address);
          });
          // this.addresses.sort(
          //   function(a,b) {
          //       return (this.$dayjs(b.created_at).unix() - this.$dayjs(a.created_at).unix())
          //   });
          commit("setAddresses", addresses);
          return addresses;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async SET_USER_ADDRESS({ state, commit }) {
    const editedIndex = getCookieValueByName("editedIndex");
    editedIndex
      ? commit("setUserAddress", state.addresses[editedIndex])
      : commit("setUserAddress", {});
  },
  async SAVE_ADDRESSES({ state, commit }) {
    try {
      const CAL_ACCESS_TOKEN = getCookieValueByName("CAL_ACCESS_TOKEN");
      let res = await this.$axios.$post("user/address", state.addresses, {
        headers: { Authorization: CAL_ACCESS_TOKEN },
      });
    } catch (error) {
      console.error(error);
    }
  },
  UPADTE_USER_ADDRESS({ state, commit }, address) {
    commit("setUserAddress", address);
  },
  UPADTE_ADDRESSES({ state, commit }, addresses) {
    commit("setAddresses", addresses);
  },
  SET_ALL_DEFAULT_FALSE({ state, commit }) {
    commit("setAddressDefaultFalse");
    commit("setAddresses", [...state.addresses]);
  },
  async UPDATE_USER_ATTRIBUTES({ _, commit }, userUpdateCred) {
    try {
      let user = await Auth.signIn(
        userUpdateCred.email,
        userUpdateCred.password
      );
      if (user) {
        delete userUpdateCred.email;
        delete userUpdateCred.password;
        const result = await Auth.updateUserAttributes(user, userUpdateCred);
        if (result === "SUCCESS") {
          user = await Auth.currentAuthenticatedUser();
          let userAttributes = {
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name,
            email: user.attributes.email,
            phone: user.attributes.phone_number || "123-456-7890",
            isEmailVerified: user.attributes.email_verified,
          };
          if (
            userAttributes.phone &&
            userAttributes.phone.length > 8 &&
            userAttributes.phone.startsWith("+1")
          ) {
            userAttributes.phone = userAttributes.phone.substring(2);
          }
          commit("setUser", userAttributes);
          return true;
        } else return false;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async UPDATE_PASSWORD({ _, commit }, userUpdateCred) {
    try {
      let user = await Auth.currentAuthenticatedUser();
      if (user) {
        const result = await Auth.changePassword(
          user,
          userUpdateCred.oldPassword,
          userUpdateCred.newPassword
        );
        return result === "SUCCESS" ? true : false;
      } else return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async CHANGE_PASSWORD({ _, commit }, payload) {
    const check = await Auth.forgotPasswordSubmit(payload.email, payload.code, payload.pass);
    if (check) {
      return check
    } else {
      throw new Error(`Invalid password ${error}`)
    }
  }
};

/**
 * Account Mutations
 */
export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserAddress(state, userAddress) {
    state.userAddress = userAddress;
  },
  setAddresses(state, addresses) {
    state.addresses = addresses;
  },
  setIsAuthenticatedUser(state, isAuthenticatedUser) {
    state.isAuthenticatedUser = isAuthenticatedUser;
  },
  setAddressDefaultFalse(state) {
    state.addresses.forEach((address) => {
      address.default = false;
    });
  },

};

/**
 * Account Getters
 */
export const getters = {
  getUser(state) {
    return state.user;
  },
  getUserAddress(state) {
    return state.userAddress;
  },
  getAddresses(state) {
    return state.addresses;
  },
  getIsAuthenticatedUser(state) {
    return state.isAuthenticatedUser;
  },
};
