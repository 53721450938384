const defaultLocale = "en-ca";
const CONVERSION_RATE = 0.79;
const localeList = [
  {
    code: "en-ca",
    iso: "en-CA",
    country: "CA",
    currency: "CAD",
    lang: "en_CA",
    priceDisplay: (price) => {
      return "$" + Math.trunc(price);
    },
    file: "en-CA.js",
    dateSuffix: (numbr, disabledLocal) => {
      return disabledLocal
        ? "rd"
        : numbr === 1
          ? "st"
          : numbr === 2
            ? "nd"
            : numbr === 3
              ? "rd"
              : "th";
    },
  },
  {
    code: "fr-ca",
    iso: "fr-CA",
    country: "CA",
    currency: "CAD",
    lang: "fr_CA",
    priceDisplay: (price) => {
      return Math.trunc(price) + "$";
    },
    file: "fr-CA.js",
    dateSuffix: (numbr, disabledLocal) => {
      return disabledLocal
        ? "rd"
        : numbr === 1
          ? "er"
          : numbr === 2
            ? "e"
            : numbr === 3
              ? "e"
              : "e";
    },
  },
];

const getFlagByCode = (code) => {
  return require(`~/assets/images/${code.split("-")[1]}.png`);
};

const localeIsoList = () => {
  return localeList.map((local) => local.iso);
};

const getCurrencyByLocale = (code) => {
  const l = localeList.filter((local) => local.code === code);
  return l[0].currency;
};

const getPriceByLocale = (code, price) => {
  const l = localeList.filter((local) => local.code === code);
  return l[0].priceDisplay(price);
};

const getDateSuffix = (code, numbr, disabledLocal) => {
  const l = localeList.filter((local) => local.code === code);
  return l[0].dateSuffix(numbr, (disabledLocal = false));
};

const getLangByLocale = (code) => {
  const l = localeList.filter((local) => local.code === code);
  return l[0].lang;
};

export {
  localeList,
  localeIsoList,
  defaultLocale,
  getFlagByCode,
  getCurrencyByLocale,
  getPriceByLocale,
  getDateSuffix,
  getLangByLocale,
};
