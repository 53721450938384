export default ({ app }) => {
    const isMobile = window.innerWidth < 768;
    if (process.client && !isMobile) {
        app.head.script.push({
            src: "https://chat-assets.frontapp.com/v1/chat.bundle.js",
            async: true,
            callback: () => {
                window.FrontChat("init", {
                    chatId: process.env.FRONT_CHAT_ID,
                    onInitCompleted: () => {
                        $nuxt.$on("hideChat", () => {
                            window.FrontChat("shutdown");
                        });
                        $nuxt.$on("showChat", () => {
                            window.FrontChat("init", {
                                chatId: process.env.FRONT_CHAT_ID,
                            });
                        });
                    }
                });
            }
        });
    }
};